import "core-js/modules/es.function.name"; //
//
//
//
//
//
//
//
//
//
//
//
//

import { isExternal as _isExternal } from '@/utils/validate';

export default {
  props: {
    to: {
      type: String,
      required: true },

    route: {
      type: Object,
      required: true } },


  computed: {
    isExternal: function isExternal(url) {
      return _isExternal(url);
    } },

  methods: {
    linkTo: function linkTo() {
      if (this.$route.name === this.route.name) {
        return;
      }
      this.$router.push({
        name: this.route.name });

    } } };